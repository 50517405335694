/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { DynamoDBClient, GetItemCommand } from "@aws-sdk/client-dynamodb";

const dynamoDBClient = new DynamoDBClient({
  region: "us-east-1",
  credentials: {
    accessKeyId: "AKIAUSD2YZRH75J5NCW6",
    secretAccessKey: "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",
  },
});

const getMetadata = async (storyId) => {
  const params = {
    TableName: "snaplibs-story-metadata",
    Key: {
      storyId: { S: storyId },
    },
  };

  try {
    const data = await dynamoDBClient.send(new GetItemCommand(params));
    return data.Item;
  } catch (err) {
    return "no data found";
  }
};

const formatDate = (date) => {
  // convert date string to browser's timezone
  const now = new Date();
  const localDate = new Date(date + ":00 UTC");
  // const weekday = date.substring(0, 3);
  // const month = localDate.substring(4, 7);
  // const day = localDate.substring(8, 10);
  // const year = date.substring(11, 15);
  // let time = localDate.substring(16, 21);

  const diffTime = Math.abs(now - localDate);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays === 0) {
    if (diffTime < 1000 * 60) {
      return "Just now";
    }
    if (diffTime < 1000 * 60 * 60) {
      if (Math.floor(diffTime / (1000 * 60)) === 1) {
        return "1 minute ago";
      }
      return Math.floor(diffTime / (1000 * 60)) + " minutes ago";
    }
    if (Math.floor(diffTime / (1000 * 60 * 60)) === 1) {
      return "1 hour ago";
    }
    return Math.floor(diffTime / (1000 * 60 * 60)) + " hours ago";
  }
  if (diffDays === 1) {
    return "Yesterday";
  }
  return `${diffDays} days ago`;
  /* AM/PM times
  if (Number(time.substring(0, 2)) <= 12) {
    return `${time} am ${month} ${day}`;
  }
  time = `${Number(time.substring(0, 2)) - 12}${time.substring(2)}`;
  return `${time} pm ${month} ${day}`;
  */
};

function StoryCard({ storyId, title, image }) {
  const [storyMetadata, setStoryMetadata] = useState("");
  useEffect(() => {
    getMetadata(storyId).then((data) => {
      setStoryMetadata(data);
    });
  }, []);
  const imageTemplate = (
    <MKBox
      borderRadius="xl"
      shadow="lg"
      minHeight="7rem"
      sx={{
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
        transformOrigin: "50% 0",
        backfaceVisibility: "hidden",
        willChange: "transform, box-shadow",
        transition: "transform 200ms ease-out",

        "&:hover": {
          transform: "perspective(3000px) rotateX(5deg) translate3d(0px, -1px, 10px)",
        },
      }}
    >
      <MKBox
        component="img"
        src={image}
        alt={title}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          objectPosition: "center",
        }}
        opacity={1}
      />
    </MKBox>
  );
  return (
    <MKBox position="relative">
      {imageTemplate}
      <MKBox mt={1} ml={1} lineHeight={1}>
        <MKTypography variant="body2" color="black">
          {storyMetadata ? formatDate(storyMetadata.date.S) : "Loading..."}
        </MKTypography>
        <MKTypography variant="h5" color="black">
          {title}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the ExampleCard
StoryCard.propTypes = {
  storyId: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
};

export default StoryCard;
