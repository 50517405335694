import { useLocation } from "react-router-dom";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Switch from "@mui/material/Switch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShareIcon from "@mui/icons-material/Share";
import ThumbUpAlt from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAlt from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownAlt from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAlt from "@mui/icons-material/ThumbDownOffAlt";
import AWS from "aws-sdk";
import { DynamoDBClient, GetItemCommand } from "@aws-sdk/client-dynamodb";

const dynamoDBClient = new DynamoDBClient({
  region: "us-east-1",
  credentials: {
    accessKeyId: "AKIAUSD2YZRH75J5NCW6",
    secretAccessKey: "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",
  },
});

const getMetadata = async (storyId) => {
  const params = {
    TableName: "snaplibs-story-metadata",
    Key: {
      storyId: { S: storyId },
    },
  };

  try {
    const data = await dynamoDBClient.send(new GetItemCommand(params));
    return data.Item;
  } catch (err) {
    return "no data found";
  }
};

AWS.config.update({
  region: "us-east-1", // Your region
  accessKeyId: "AKIAUSD2YZRH75J5NCW6",
  secretAccessKey: "pi/fwC0fAyoIW53WdH/B+9el10JNAYNx7XT67XvB",
});

const lambda = new AWS.Lambda();

const updateMetadata = async (storyId, like, isPublic, isMyStory) => {
  const historicLike = localStorage.getItem(storyId + "_likes");
  let likeChange = 0;
  let dislikeChange = 0;

  if (!historicLike) {
    localStorage.setItem(storyId + "_likes", `${like}`);
    like === 1 ? (likeChange = 1) : like === -1 ? (dislikeChange = 1) : null;
  } else if (historicLike !== `${like}`) {
    localStorage.setItem(storyId + "_likes", `${like}`);
    like === 1 ? (likeChange = 1) : like === -1 ? (dislikeChange = 1) : null;
    historicLike === "1" ? (likeChange = -1) : historicLike === "-1" ? (dislikeChange = -1) : null;
  }
  const params = {
    FunctionName: "snaplibs-update-metadata",
    InvocationType: "Event",
    Payload: JSON.stringify({
      body: {
        storyId: storyId,
        like: likeChange,
        dislike: dislikeChange,
        isPublic: isPublic,
        isMyStory: isMyStory,
      },
    }),
  };
  try {
    await lambda.invoke(params).promise();
    return;
  } catch (err) {
    console.log("Error");
  }
};

async function getStoryFromLambda(storyId) {
  const params = {
    FunctionName: "DownloadWordSoupStoryFromS3", // Your Lambda function name
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({ body: storyId }),
  };

  try {
    const result = await lambda.invoke(params).promise();
    const payload = JSON.parse(JSON.parse(result.Payload).body);
    return payload;
  } catch (err) {
    console.log("Error");
  }
}

function resizeImage(img, width, height) {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    const resizedImageDataUrl = canvas.toDataURL("image/png");
    resolve(resizedImageDataUrl);
  });
}

export default function StoryPage() {
  const isExtraSmallScreen = useMediaQuery("(max-width:450px)");
  const isSmallScreen = useMediaQuery("(max-width:551px) and (min-width:451px)");
  const isMediumScreen = useMediaQuery("(min-width:551px) and (max-width:960px)");
  const isLargeScreen = useMediaQuery("(min-width:961px) and (max-width:2000px)");
  const isExtraLargeScreen = useMediaQuery("(min-width:2000px)");

  const [resizedImage, setResizedImage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [title, setTitle] = useState("");
  const titleRef = useRef(title);
  const [story, setStory] = useState("");
  const storyRef = useRef(story);
  const [image, setImage] = useState("");
  const imageRef = useRef(image);
  const [imgDim, setImgDim] = useState(1024);
  const storyId = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
  const [isPublic, setIsPublic] = useState(false);
  const isPublicRef = useRef(isPublic);
  const thisUserIdRef = useRef(
    localStorage.getItem("userId") ? localStorage.getItem("userId") : ""
  );
  const [storyUserId, setStoryUserId] = useState("");
  const storyUserIdRef = useRef(storyUserId);
  const [like, setLike] = useState(0);
  const likeRef = useRef(like);
  const [storyMetadata, setStoryMetadata] = useState("");
  const storyMetadataRef = useRef(storyMetadata);

  useEffect(() => {
    titleRef.current = title;
    storyRef.current = story;
    imageRef.current = image;
    isPublicRef.current = isPublic;
    storyUserIdRef.current = storyUserId;
  }, [title, story, image, isPublic, storyUserId]);

  useEffect(() => {
    likeRef.current = like;
  }, [like]);

  useEffect(() => {
    if (localStorage.getItem(storyId + "_likes")) {
      setLike(Number(localStorage.getItem(storyId + "_likes")));
    }
    const fetchData = async () => {
      setStoryMetadata(await getMetadata(storyId));
    };
    fetchData();
  }, []);

  useEffect(() => {
    storyMetadataRef.current = storyMetadata;
    console.log(storyMetadataRef.current);
    if (storyMetadata.isPublic) {
      setIsPublic(storyMetadata.isPublic.BOOL);
    }
    console.log(storyMetadata);
  }, [storyMetadata]);

  useEffect(() => {
    // If the user navigated from input or home page
    async function fetchData() {
      if (location.state) {
        if (location.state.intervalId) {
          clearInterval(location.state.intervalId);
        }
        setStory(location.state.storyString);
        setTitle(location.state.title);
        if (location.state.image) {
          setImage(location.state.image);
        }
        if (location.state.userId) {
          setStoryUserId(location.state.userId);
        }
      } else {
        const data = await getStoryFromLambda(storyId);
        if (data) {
          setTitle(data.title);
          setStory(data.storyString);
          setImage(data.image);
          setStoryUserId(data.userId);
        } else {
          navigate("/");
        }
      }
    }
    fetchData();
  }, [location]);

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      resizeImage(img, imgDim, imgDim).then((resizedImageDataUrl) => {
        setResizedImage(resizedImageDataUrl);
      });
    };
  }, [imgDim, image]);

  useEffect(() => {
    if (isExtraSmallScreen) {
      setImgDim(400);
    } else if (isSmallScreen) {
      setImgDim(500);
    } else if (isMediumScreen) {
      setImgDim(550);
    } else if (isLargeScreen) {
      setImgDim(640);
    } else {
      setImgDim(1024);
    }
  }, [isExtraSmallScreen, isSmallScreen, isMediumScreen, isLargeScreen, isExtraLargeScreen]);

  const handleBackClick = () => {
    navigate("/"); // Navigate to the home page
  };

  const formatText = (text) => {
    if (text) {
      return text.replace(/\*(.*?)\*/g, "<strong>$1</strong>");
    }
    return "";
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        localStorage.setItem("lastSharedDate", new Date().toString().substring(0, 15));
        await navigator.share({
          title: "My Story at snaplibs.com",
          text: titleRef.current,
          url: window.location.href,
        });
        console.log("Content shared successfully!");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      copyToClipboard();
    }
  };
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => alert("Link copied to clipboard!"))
      .catch((error) => console.error("Failed to copy:", error));
  };

  useEffect(() => {
    return () => {
      updateMetadata(
        storyId,
        likeRef.current,
        isPublicRef.current,
        storyUserIdRef.current === thisUserIdRef.current
      );
      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
      }
    };
  }, []);

  return (
    <MKBox
      sx={{
        display: "flex",
        ...((isLargeScreen || isExtraLargeScreen) && { flexDirection: "row" }),
        ...((isMediumScreen || isSmallScreen || isExtraSmallScreen) && { flexDirection: "column" }),
        height: "100vh",
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#f4f1ec",
      }}
    >
      <MKBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...(isLargeScreen && { height: "100vh", width: "100vh" }),
          ...(isMediumScreen && { height: "98vw", width: "100vw" }),
          ...((isSmallScreen || isExtraSmallScreen) && { height: "100vw", width: "100vw" }),
        }}
      >
        <MKBox
          component="img"
          src={resizedImage}
          alt="Image Loading"
          sx={{
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "cover",
            width: "auto",
            height: "auto",
          }}
        />
      </MKBox>
      <MKBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          ...((isLargeScreen || isExtraLargeScreen) && { height: "100vh", width: "120vh" }),
          ...((isMediumScreen || isSmallScreen || isExtraSmallScreen) && {
            height: "100vh - 100wv",
            width: "100vw",
          }),
        }}
      >
        <MKTypography
          variant="h2"
          fontFamily="Pangolin"
          color="black"
          padding="10px"
          textAlign="center"
        >
          {title}
        </MKTypography>
        <MKTypography
          variant="body1"
          boxSizing="border-box"
          width="80%"
          color="black"
          dangerouslySetInnerHTML={{ __html: formatText(story) }}
          sx={{
            fontSize: {
              xs: "1.1rem",
              sm: "1.1rem",
              md: "1.35rem",
              lg: "1.35rem",
              xl: "1.35rem",
            },
          }}
        />
        {storyUserIdRef.current === thisUserIdRef.current &&
        (storyMetadata.isAppropriate ? storyMetadata.isAppropriate.BOOL : true) ? (
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <MKBox
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Switch
                checked={isPublic}
                onChange={() => setIsPublic(!isPublicRef.current)}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "#ed7a40 !important", // Color of the switch thumb when checked
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "#ed7a40 !important", // Color of the switch track when checked
                  },
                  "& .MuiSwitch-switchBase": {
                    color: "#ed7a40 !important", // Color of the switch thumb when unchecked
                  },
                }}
              />
              <MKTypography>{isPublic ? "Make Private" : "Make Public"}</MKTypography>
            </MKBox>
            {isPublic ? (
              <MKBox>
                <MKTypography variant="body2" color="black" sx={{ whiteSpace: "pre-line" }}>
                  {storyMetadata ? storyMetadata.views.N : 0} {"Views "}
                  {storyMetadata
                    ? storyMetadata.numLikes.N === "1"
                      ? "1 Like    "
                      : storyMetadata.numLikes.N + " Likes    "
                    : 0}
                </MKTypography>
              </MKBox>
            ) : null}
          </MKBox>
        ) : (
          <MKBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "30px" }}
          >
            {like === 1 ? (
              <ThumbUpAlt onClick={() => setLike(0)} fontSize="medium" />
            ) : (
              <ThumbUpOffAlt onClick={() => setLike(1)} fontSize="medium" />
            )}
            <MKTypography marginLeft="5px" marginRight="5px">
              {storyMetadata ? Number(storyMetadata.numLikes.N) + (like === 1 ? 1 : 0) : 1}
            </MKTypography>
            {like === -1 ? (
              <ThumbDownAlt onClick={() => setLike(0)} fontSize="medium" />
            ) : (
              <ThumbDownOffAlt onClick={() => setLike(-1)} fontSize="medium" />
            )}
          </MKBox>
        )}
        <MKBox
          sx={{
            display: "relative",
            flexDirection: "row",
            alignItems: "center",
            mb: isExtraSmallScreen ? "50px" : "10px",
          }}
        >
          <Button
            variant="extended"
            size="large"
            onClick={handleBackClick}
            startIcon={<ArrowBackIcon />}
            sx={{
              marginRight: "20px",
              fontSize: "1rem",
              "& .MuiButton-startIcon": {
                fontSize: "2rem",
              },
            }}
          >
            Back
          </Button>
          <Button
            variant="extended"
            size="large"
            endIcon={<ShareIcon />}
            sx={{
              marginLeft: "20px",
              fontSize: "1rem",
              "& .MuiButton-endIcon": {
                fontSize: "2rem",
              },
            }}
            onClick={handleShare}
          >
            Share
          </Button>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}
