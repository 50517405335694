/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/bg-presentation.jpg";

// React
import { useState } from "react";
import Create from "./Create";
import Discover from "./Discover";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useMediaQuery from "@mui/material/useMediaQuery";

function HomePage() {
  const isMediumScreen = useMediaQuery("max-width:960px");
  const isLargeScreen = useMediaQuery("(min-width:961px) and (max-width:2000px)");
  const [tabValue, setTabValue] = useState("Create");

  const generateRandomCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  if (localStorage.getItem("userId") === null) {
    console.log("userId created");
    localStorage.setItem("userId", generateRandomCode());
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <MKBox
      sx={{
        backgroundColor: "#f4f1ec",
        minHeight: "100vh",
        ...(isLargeScreen & { width: "99vw" }),
        ...(isMediumScreen & { width: "96vw" }),
        pb: "20px",
      }}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <MKBox
        minHeight="200px"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "auto",
          backgroundRepeat: "repeat",
          backgroundPosition: "top center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto" direction="column">
            <MKTypography
              variant="h1"
              color="black"
              textAlign="center"
              mt={-6}
              mb={1}
              sx={{
                fontFamily: "Caveat",
                fontSize: "5rem",
              }}
            >
              Snap Libs
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          pt: 0,
          pb: 0,
          mt: -8,
          mb: 6,
          mx: { xs: 2, lg: 3 },
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Box sx={{ width: "35%", minWidth: "300px", margin: "0 auto", marginTop: "20px" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            centered
            sx={{
              "& .MuiTouchRipple-root .MuiTouchRipple-ripple .MuiTouchRipple-child": {
                backgroundColor: "#cccccc", // Replace with your desired color
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "primary.main", // Underline color
                height: 2, // Underline thickness
                transition: "all 0.2s ease", // Smooth transition
                boxShadow: "none",
              },
            }}
          >
            <Tab label="Create" value="Create" />
            <Tab label="Discover" value="Discover" />
          </Tabs>
        </Box>
        {tabValue === "Create" && <Create />}
        {tabValue === "Discover" && <Discover />}
      </Card>
    </MKBox>
  );
}

export default HomePage;
