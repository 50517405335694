import { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MKBox from "components/MKBox";
import StoryCard from "./StoryCard";
import { Link } from "react-router-dom";
import MKTypography from "components/MKTypography";
import AWS from "aws-sdk";

AWS.config.update({
  region: "us-east-1", // Your region
  accessKeyId: "AKIAUSD2YZRH75J5NCW6",
  secretAccessKey: "pi/fwC0fAyoIW53WdH/B+9el10JNAYNx7XT67XvB",
});

const lambda = new AWS.Lambda();

async function downloadStoryUsingLambda(storyId) {
  const params = {
    FunctionName: "DownloadWordSoupStoryFromS3", // Your Lambda function name
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({ body: storyId }),
  };

  try {
    const result = await lambda.invoke(params).promise();
    const payload = JSON.parse(result.Payload);
    return payload.body;
  } catch (err) {
    console.error("Error");
  }
}

async function getDynamoDBLog(key) {
  const params = {
    TableName: "snaplibs-hourly-log",
    Key: {
      DateTime: key,
    },
  };

  const docClient = new AWS.DynamoDB.DocumentClient();

  try {
    const data = await docClient.get(params).promise();
    return data.Item;
  } catch (err) {
    console.error("Error", err);
  }
}

const formatUTCDate = (date) => {
  return (
    date.substring(0, 3) + date.substring(7, 11) + date.substring(4, 7) + date.substring(11, 16)
  );
};

function TopStories() {
  const dayIndexRef = useRef(-1); // 0 = today, 1 = yesterday, etc
  const todayRef = useRef(formatUTCDate(new Date().toUTCString()));
  const [data, setData] = useState("");
  const dataRef = useRef(data);
  const [storyJson, setStoryJson] = useState([]);
  const storyJsonRef = useRef(storyJson);
  const numLoadedRef = useRef(0);
  const [maxDisplay, setMaxDisplay] = useState(6);
  const [isLoading, setIsLoading] = useState(false);

  const getNextDayData = async () => {
    dayIndexRef.current = dayIndexRef.current + 1;
    todayRef.current = formatUTCDate(
      new Date(new Date().setDate(new Date().getDate() - dayIndexRef.current)).toUTCString()
    );
    let result = await getDynamoDBLog(todayRef.current);
    while ((!result || result.log === undefined || result.log === "") && dayIndexRef.current < 20) {
      dayIndexRef.current = dayIndexRef.current + 1;
      todayRef.current = formatUTCDate(
        new Date(new Date().setDate(new Date().getDate() - dayIndexRef.current)).toUTCString()
      );
      result = await getDynamoDBLog(todayRef.current);
    }
    if (!result) {
      return "";
    }
    return result.log;
  };

  useEffect(() => {
    storyJsonRef.current = storyJson;
  }, [storyJson]);

  useEffect(() => {
    const fetchData = async () => {
      let result = await getNextDayData();
      setData(result);
      setMaxDisplay(8);
    };
    fetchData();
  }, []);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const fetchStories = async () => {
      setIsLoading(true);
      const storyIdsArrays = data.split(" ").slice(1);
      storyIdsArrays.reverse();

      const storyPromises = storyIdsArrays.map(async (storyId, index) => {
        if (index < maxDisplay) {
          const storyData = await downloadStoryUsingLambda(storyId);
          if (storyData) {
            return JSON.parse(storyData);
          }
        }
        return null;
      });

      const resolvedStories = await Promise.all(storyPromises);
      const stories = resolvedStories.filter((story) => story !== null);
      setStoryJson(stories);
      numLoadedRef.current = stories.length;
      setIsLoading(false);
      if (stories.length < maxDisplay) {
        fetchMoreStories();
      }
    };

    const fetchMoreStories = async () => {
      setIsLoading(true);
      if (data.split(" ").slice(1).length < maxDisplay) {
        let newDayData = await getNextDayData();
        dataRef.current = dataRef.current + newDayData;
        setData(dataRef.current);
      }
      const storyIdsArrays = dataRef.current.split(" ").slice(1);

      const newStoryPromises = storyIdsArrays.map(async (storyId, index) => {
        if (index < maxDisplay && index >= numLoadedRef.current) {
          const storyData = await downloadStoryUsingLambda(storyId);
          if (storyData) {
            return JSON.parse(storyData);
          }
        }
        return null;
      });

      const resolvedStories = await Promise.all(newStoryPromises);
      const stories = resolvedStories.filter((story) => story !== null);
      storyJsonRef.current = storyJsonRef.current.concat(stories);
      setStoryJson(storyJsonRef.current);
      numLoadedRef.current = storyJsonRef.current.length;
      setIsLoading(false);
      if (storyJsonRef.current.length < maxDisplay && stories.length > 0) {
        fetchMoreStories();
      }
    };
    if (data) {
      if (maxDisplay <= 8) {
        fetchStories();
      } else {
        fetchMoreStories();
      }
    }
  }, [maxDisplay]);

  const renderData = (
    <Grid container spacing={3} sx={{ mb: 10 }}>
      <Grid item xs={12} lg={12}>
        <Grid container spacing={3}>
          {storyJson.map((storyData) => {
            const id = storyData.storyId;
            const title = storyData.title;
            const image = storyData.image;
            return (
              <Grid item xs={6} md={3} sx={{ mb: 2 }} key={id}>
                <Link to={"/story/" + id} state={storyData}>
                  <StoryCard storyId={id} title={title} image={image} />
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );

  let loadButton = (
    <MKBox width="80vw" display="flex" alignItems="center" justifyContent="center" mb="10px">
      <Button size="large" onClick={() => !isLoading && setMaxDisplay(maxDisplay + 16)}>
        {isLoading ? (
          <CircularProgress
            size={24}
            display="flex"
            sx={{
              color: "white",
              backgroundColor: "transparent",
              "& .MuiCircularProgress-circle": {
                stroke: "blue",
              },
            }}
          />
        ) : (
          "Load More"
        )}
      </Button>
    </MKBox>
  );

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      component="section"
      py="6"
      my="6"
      sx={{
        mx: "5vw",
      }}
    >
      <MKTypography variant="h2" fontWeight="bold" mt="15px" mb="20px">
        {"Top Stories"}
      </MKTypography>
      {renderData}
      {loadButton}
    </MKBox>
  );
}

export default TopStories;
