/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded y www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import HomePage from "pages/HomePage";
import InputPage from "layouts/pages/input";
import StoryPage from "layouts/pages/story";
import LoadingPage from "layouts/pages/loading";
import MysteryInputPage from "layouts/pages/MysteryInput";

import "./global.css";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route
          path="/"
          element={
            <div className="home-page">
              <HomePage />
            </div>
          }
        />
        <Route path="/input" element={<InputPage />} />
        <Route path="/mysteryInput" element={<MysteryInputPage />} />
        <Route path="/story/:id" element={<StoryPage />} />
        <Route path="/loading" element={<LoadingPage />} />
      </Routes>
    </ThemeProvider>
  );
}
