/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page components
import StoryCard from "pages/HomePage/StoryCard";

import AWS from "aws-sdk";

AWS.config.update({
  region: "us-east-1", // Your region
  accessKeyId: "AKIAUSD2YZRH75J5NCW6",
  secretAccessKey: "pi/fwC0fAyoIW53WdH/B+9el10JNAYNx7XT67XvB",
});

const lambda = new AWS.Lambda();

async function downloadStoryUsingLambda(storyId) {
  const params = {
    FunctionName: "DownloadWordSoupStoryFromS3", // Your Lambda function name
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({ body: storyId }),
  };

  try {
    const result = await lambda.invoke(params).promise();
    const payload = JSON.parse(result.Payload);
    return payload.body;
  } catch (err) {
    console.error("Error");
  }
}

function History() {
  const [dates, setDates] = useState([]);
  const [dayLoadingIndex, setDayLoadingIndex] = useState(0);
  const [numLoaded, setNumLoaded] = useState(0);
  const [maxDisplay, setMaxDisplay] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const data = localStorage.getItem("storyLog");
  const [examples, setExamples] = useState([]);
  const regex = /(\t|=)\w{8}/g;

  useEffect(() => {
    const downloadExmples = async () => {
      const story1 = await downloadStoryUsingLambda("sak6YAVO");
      const story2 = await downloadStoryUsingLambda("PNl7p2TG");
      const story3 = await downloadStoryUsingLambda("hgoaYaWN");
      const story4 = await downloadStoryUsingLambda("d5dPlUTa");
      setExamples([JSON.parse(story1), JSON.parse(story2), JSON.parse(story3), JSON.parse(story4)]);
    };
    downloadExmples();
  }, []);

  if (!data) {
    return (
      <MKBox
        width="80vw"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mb="10px"
      >
        <MKTypography variant="h2" mt="60px" mb="10px" align="center">
          {"Welcome"}
        </MKTypography>
        <MKBox
          width="300px"
          display="flex"
          alignItems="center"
          align="center"
          justifyContent="center"
          mb="30px"
        >
          <MKTypography variant="body1">
            {
              "Snap Libs are AI Mad Libs with pictures. Enter your title, type a few words, and enjoy!"
            }
          </MKTypography>
        </MKBox>
        {examples.length !== 0 ? (
          <Grid container spacing={3} sx={{ mb: 10 }} key={"examples"}>
            <Grid item xs={12} lg={3}>
              <MKBox position="sticky" top="100px">
                <MKTypography variant="h4" mb="2" width="80vw">
                  {"Examples"}
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={3} sx={{ mb: 2 }} key={0}>
                  <Link to={"/story/" + examples[0].storyId} state={examples[0]}>
                    <StoryCard
                      storyId={examples[0].storyId}
                      title={examples[0].title}
                      image={examples[0].image}
                    />
                  </Link>
                </Grid>
                <Grid item xs={6} md={3} sx={{ mb: 2 }} key={1}>
                  <Link to={"/story/" + examples[1].storyId} state={examples[1]}>
                    <StoryCard
                      storyId={examples[1].storyId}
                      title={examples[1].title}
                      image={examples[1].image}
                    />
                  </Link>
                </Grid>
                <Grid item xs={6} md={3} sx={{ mb: 2 }} key={2}>
                  <Link to={"/story/" + examples[2].storyId} state={examples[2]}>
                    <StoryCard
                      storyId={examples[2].storyId}
                      title={examples[2].title}
                      image={examples[2].image}
                    />
                  </Link>
                </Grid>
                <Grid item xs={6} md={3} sx={{ mb: 2 }} key={3}>
                  <Link to={"/story/" + examples[3].storyId} state={examples[3]}>
                    <StoryCard
                      storyId={examples[3].storyId}
                      title={examples[3].title}
                      image={examples[3].image}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress
            size={24}
            sx={{
              color: "white",
              backgroundColor: "transparent",
              "& .MuiCircularProgress-circle": {
                stroke: "blue",
              },
            }}
          />
        )}
      </MKBox>
    );
  } else {
    const numStories = (data.match(regex) || []).length;
    useEffect(() => {
      const fetchStories = async () => {
        setIsLoading(true);
        const dateStrings = data
          .split("||")
          .slice(1)
          .map((daysData) => daysData.split("=")[0]);
        dateStrings.reverse();

        const storyIdsArrays = data
          .split("||")
          .slice(1)
          .map((oneDaysData) => oneDaysData.split("=")[1].split("\t").reverse());
        storyIdsArrays.reverse();

        let day = 0;
        let i = 0;
        const storyPromises = storyIdsArrays.flat().map(async (storyId, index) => {
          if (index < maxDisplay) {
            if (storyId !== storyIdsArrays[day][index - i]) {
              day++;
              i = index;
            }
            if (localStorage.getItem(storyId) !== null) {
              return JSON.parse(localStorage.getItem(storyId));
            }
            const storyData = await downloadStoryUsingLambda(storyId);
            if (storyData) {
              return JSON.parse(storyData);
            }
          }
          return null;
        });

        const renderDates = [];
        const storiesPerDay = storyIdsArrays.map((ids) => ids.length);
        const resolvedStories = await Promise.all(storyPromises);
        let storyIndex = 0;
        for (let i = 0; i < storiesPerDay.length; i++) {
          let storiesForDay = [];
          for (let j = 0; j < storiesPerDay[i]; j++) {
            if (resolvedStories[storyIndex] !== null) {
              storiesForDay.push(resolvedStories[storyIndex]);
              storyIndex++;
            }
          }
          renderDates.push([dateStrings[i], storiesForDay]);
        }
        setDates(renderDates);
        setDayLoadingIndex(day);
        setNumLoaded(maxDisplay);
        setIsLoading(false);
      };

      const fetchMoreStories = async () => {
        setIsLoading(true);
        const dateStrings = data
          .split("||")
          .slice(1)
          .map((daysData) => daysData.split("=")[0]);
        dateStrings.reverse();

        const storyIdsArrays = data
          .split("||")
          .slice(1)
          .map((oneDaysData) => oneDaysData.split("=")[1].split("\t").reverse());
        storyIdsArrays.reverse();

        let day = dayLoadingIndex;
        let i = 0;
        const newStoryPromises = storyIdsArrays.flat().map(async (storyId, index) => {
          if (index < maxDisplay && index >= numLoaded) {
            if (i === 0) {
              i = index - storyIdsArrays[day].indexOf(storyId);
            }
            if (storyId !== storyIdsArrays[day][index - i]) {
              day++;
              i = index;
            }
            if (localStorage.getItem(storyId) !== null) {
              return JSON.parse(localStorage.getItem(storyId));
            }
            const storyData = await downloadStoryUsingLambda(storyId);
            if (storyData) {
              return JSON.parse(storyData);
            }
          }
          return null;
        });

        const renderDates = [];
        const storiesPerDay = storyIdsArrays.map((ids) => ids.length);
        const resolvedStories = await Promise.all(newStoryPromises);
        let storyIndex = 0;
        for (let i = 0; i < storiesPerDay.length; i++) {
          let storiesForDay = [];
          if (i < dayLoadingIndex) {
            renderDates.push(dates[i]);
            storyIndex += storiesPerDay[i];
          } else {
            for (let j = 0; j < storiesPerDay[i]; j++) {
              if (storyIndex < numLoaded && dates[i][1].length > j && storyIndex < maxDisplay) {
                storiesForDay.push(dates[i][1][j]);
              } else if (resolvedStories[storyIndex] !== null) {
                storiesForDay.push(resolvedStories[storyIndex]);
              }
              storyIndex++;
            }
            renderDates.push([dateStrings[i], storiesForDay]);
          }
        }
        setDates(renderDates);
        setDayLoadingIndex(day);
        setNumLoaded(maxDisplay);
        setIsLoading(false);
      };

      if (maxDisplay <= 8) {
        fetchStories();
      } else {
        fetchMoreStories();
      }
    }, [data, maxDisplay]);

    const renderData = dates.map((dateData) => {
      if (dateData[1].length === 0) {
        return null;
      }
      return (
        <Grid container spacing={3} sx={{ mb: 10 }} key={dateData[0]}>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px">
              <MKTypography variant="h4" mb="2" width="80vw">
                {dateData[0]}
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={3}>
              {dateData[1].map((storyData) => {
                const id = storyData.storyId;
                const title = storyData.title;
                const image = storyData.image;
                return (
                  <Grid item xs={6} md={3} sx={{ mb: 2 }} key={id}>
                    <Link to={"/story/" + id} state={storyData}>
                      <StoryCard storyId={id} title={title} image={image} />
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      );
    });

    let loadButton = (
      <MKBox width="80vw" display="flex" alignItems="center" justifyContent="center" mb="10px">
        <Button size="large" onClick={() => !isLoading && setMaxDisplay(maxDisplay + 16)}>
          {isLoading ? (
            <CircularProgress
              size={24}
              sx={{
                color: "white",
                backgroundColor: "transparent",
                "& .MuiCircularProgress-circle": {
                  stroke: "blue",
                },
              }}
            />
          ) : (
            "Load More"
          )}
        </Button>
      </MKBox>
    );
    if (numStories <= maxDisplay) {
      loadButton = null;
    }

    return (
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        component="section"
        py="6"
        my="6"
        sx={{
          mx: "5vw",
        }}
      >
        <MKTypography variant="h2" fontWeight="bold" mt="60px" mb="10px">
          {"My Stories"}
        </MKTypography>
        {renderData}
        {loadButton}
      </MKBox>
    );
  }
}

export default History;
