import React, { useState, useEffect, useRef } from "react"; //, createContext, useContext } from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import PropTypes from "prop-types";
import { useSprings, animated, to as interpolate } from "@react-spring/web";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { randomWord } from "modules/randomWord";
import { Filter } from "bad-words";
import sound1 from "card_1.mp3";
import sound2 from "card_2.mp3";

import styles from "./styles.module.css";

import AWS from "aws-sdk";

AWS.config.update({
  region: "us-east-1", // Your region
  accessKeyId: "AKIAUSD2YZRH75J5NCW6",
  secretAccessKey: "pi/fwC0fAyoIW53WdH/B+9el10JNAYNx7XT67XvB",
});

function Deck(props) {
  const isSmallScreen = useMediaQuery("(max-width:960px)");
  const isMediumScreen = useMediaQuery("(min-width:501px) and (max-width:960px)");

  // These two are just helpers, they curate spring data, values that are later being interpolated into css
  const to = (i) => ({
    x: 0,
    y: i * -4 - 75 * isSmallScreen,
    scale: 1,
    rot: -10 + Math.random() * 20,
    delay: i * 100,
  });
  const from = () => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
  // This is being used down there in the view, it interpolates rotation and scale into a css transform
  const trans = (r, s) =>
    `perspective(2000px) rotateX(20deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

  const unfilteredPrompts = props.story
    .split("[")
    .slice(1)
    .map((element) => element.split("]")[0].toLowerCase());
  let prompts = [];
  unfilteredPrompts.forEach((element) => {
    if (!prompts.includes(element)) {
      prompts.push(element);
    }
  });

  prompts = prompts.filter((element) => element !== "n" && prompts !== undefined);
  prompts.reverse();
  const [current, setCurrent] = useState(prompts.length); // Index of the current card
  const currentRef = useRef(current);
  const [springProps, api] = useSprings(prompts.length, (i) => ({ ...to(i), from: from(i) })); // Create a bunch of springs using the helpers above
  const refs = useRef(Array.from({ length: prompts.length }, () => React.createRef()));
  const response = useRef([]);
  const navigate = useNavigate();
  const audioRef1 = useRef(new Audio(sound1));
  const audioRef2 = useRef(new Audio(sound2));
  const filter = new Filter();

  const [inputValues, setInputValues] = useState(Array(prompts.length).fill(""));

  const handleInputChange = (index, event) => {
    const newValues = [...inputValues];
    newValues[index] = event.target.value;
    setInputValues(newValues);
  };

  const setRandomWord = (index) => {
    const randomValue = randomWord(prompts[index].split("_")[0]);
    const newValues = [...inputValues];
    newValues[index] = randomValue;
    setInputValues(newValues);

    // Use the ref to change the value of the input field
    if (refs.current[index]) {
      refs.current[index].current.querySelector("input").value = randomValue;
      refs.current[index].current.querySelector("input").focus();
    }
  };

  // Function to handle Enter key release
  const handleKeyUp = async (event) => {
    if (event.key === "Enter" && currentRef.current > 0) {
      submitResponse();
    }
  };

  const isInapropriate = (text) => {
    return filter.isProfane(text);
  };

  const submitResponse = async () => {
    let text = refs.current[currentRef.current - 1].current.querySelector("input").value;
    if (text.length > 50 || isInapropriate(text)) {
      text = "";
    }
    if (text) {
      setCurrent((prevCurrent) => (prevCurrent > 0 ? prevCurrent - 1 : 0));
      response.current.push(text);
    } else {
      const input = refs.current[currentRef.current - 1].current.querySelector("input");
      if (input) {
        input.style.outline = "2px solid red";
        input.style.borderRadius = "6px";
        input.value = "";
        input.placeholder = "Invalid response";
        input.focus();
      }
    }
  };

  // Format the story
  const prepareStory = () => {
    const prompts = props.story
      .split("[")
      .slice(1)
      .map((part) => part.split("]")[0]);

    const promptsUsed = {};
    promptsUsed["n"] = "n";

    const story_parts = props.story
      .split("[")
      .slice(1)
      .map((part) => part.split("]")[1]);
    story_parts.unshift(props.story.split("[")[0]);

    let story_string = story_parts[0];
    let j = 0;
    for (let i = 1; i < story_parts.length; i++) {
      if (promptsUsed[prompts[i - 1]]) {
        story_string = `${story_string}*${promptsUsed[prompts[i - 1]]}*${story_parts[i]}`;
      } else {
        promptsUsed[prompts[i - 1]] = response.current[j];
        story_string = `${story_string}*${response.current[j]}*${story_parts[i]}`;
        j++;
      }
    }
    return story_string;
  };

  useEffect(() => {
    currentRef.current = current;
  }, [current]);

  useEffect(() => {
    api.start((i) => {
      if (i != current) return;
      let x = -0.3 * screen.width;
      let y = -1 * screen.height;
      let rot = -360;
      let scale = 0.6;

      var variant = Math.random();
      if (variant < 0.5) {
        x = -1 * screen.width;
        y = -0.4 * screen.height;
        rot = -720;
        scale = 0.6;
        audioRef1.current.play();
      } else {
        audioRef2.current.play();
      }
      return {
        x,
        y,
        rot,
        scale,
        delay: undefined,
        config: { friction: 100, tension: 400 },
      };
    });

    api.start((i) => {
      if (current - 1 == i) {
        const rot = 0; // How much the card tilts, flicking it harder makes it rotate faster
        const scale = 1.05; // Active cards lift up a bit
        return {
          rot,
          scale,
          delay: undefined,
        };
      }
    });
    if (current === 0) {
      const storyId = props.storyId;
      const title = props.title;
      const storyString = prepareStory();
      const image = props.image;
      const userId = props.userId;
      console.log([storyId, title, storyString, image]);

      navigate("/story/" + storyId, {
        state: {
          title: title,
          storyString: storyString,
          image: image,
          userId: userId,
        },
      });
    }
    if (
      refs.current[current - 1] &&
      refs.current[current - 1].current &&
      current != prompts.length
    ) {
      refs.current[current - 1].current.querySelector("input").focus();
    }
  }, [current]);

  useEffect(() => {
    if (window.location.hostname !== "localhost") {
      // Replace the current page in the history stack
      history.replaceState(null, null, "https://snaplibs.com/mysteryInput");
      // Optionally push the next page to history if needed
      history.pushState(null, null, "https://snaplibs.com");
    }
  }, []);

  // Add event listener for keyup event
  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  let typographyVariant = "h1";
  if (isMediumScreen || isSmallScreen) {
    typographyVariant = "h2";
  }
  // Now we"re just mapping the animated values to our view, that"s it. Btw, this component only renders once. :-)
  return (
    <div className={styles.container}>
      <MKBox
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <MKTypography
          variant="h2"
          color="black"
          mb="10vh"
          sx={{
            textAlign: "center",
          }}
        >
          ?
        </MKTypography>
        <MKBox sx={{ width: "70vw", height: "65vh" }} alignItems="center" justifyContent="center" />
        <CircularProgress
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        {springProps.map(({ x, y, rot, scale }, i) => (
          <animated.div className={styles.deck} key={i} style={{ x, y }}>
            <animated.div
              style={{
                transform: interpolate([rot, scale], trans),
              }}
            >
              <MKBox
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MKTypography
                  variant={typographyVariant}
                  fontFamily="Pangolin"
                  color="black"
                  mb={1}
                  mt="6vh"
                >
                  {prompts[i].split("_")[0]}
                </MKTypography>
                <MKInput
                  id={i}
                  type="text"
                  autoComplete="off"
                  ref={refs.current[i]}
                  value={inputValues[i]}
                  onChange={(event) => handleInputChange(i, event)}
                  sx={{ width: "175px" }}
                />
                <MKBox display="flex" flexDirection="row" alignItems="center" mt={2}>
                  <Button
                    onClick={() => setRandomWord(i)}
                    sx={{
                      fontSize: "1rem",
                    }}
                  >
                    {"Random"}
                  </Button>
                  <Button
                    onClick={submitResponse}
                    sx={{
                      fontSize: "1rem",
                    }}
                  >
                    {"Enter"}
                  </Button>
                </MKBox>
                <MKTypography variant="h5" color="black">
                  {prompts.length - current}/{prompts.length}
                </MKTypography>
              </MKBox>
            </animated.div>
          </animated.div>
        ))}
      </MKBox>
    </div>
  );
}

Deck.propTypes = {
  title: PropTypes.string.isRequired,
  story: PropTypes.string.isRequired,
  storyId: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  x: PropTypes.object.isRequired,
  y: PropTypes.object.isRequired,
  rot: PropTypes.object.isRequired,
  scale: PropTypes.object.isRequired,
  map: PropTypes.func.isRequired,
};

export default function MysteryInputPage() {
  const location = useLocation();
  const storyData = JSON.parse(location.state.storyData) || "";
  const title = storyData.title || "";
  const story = storyData.originalStory || "";
  const image = storyData.image || "";
  const storyId = storyData.storyId || "";
  const userId = storyData.userId || "";
  const isExtraSmallScreen = useMediaQuery("max-width:500px");
  const isMediumScreen = useMediaQuery("(min-width:501px) and (max-width:960px)");
  const isLargeScreen = useMediaQuery("(min-width:961px) and (max-width:2000px)");
  return (
    <MKBox
      sx={{
        backgroundColor: "#f4f1ec",
        ...(isLargeScreen && { width: "100vw" }),
        ...(isMediumScreen && { width: "97vw" }),
        ...(isExtraSmallScreen && { width: "90vw" }),
      }}
    >
      <link
        href="https://fonts.googleapis.com/css2?family=Pangolin:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <Deck title={title} story={story} image={image} storyId={storyId} userId={userId} />
    </MKBox>
  );
}
