import MKBox from "components/MKBox";

import { randomTitle } from "modules/randomWord";
import { Filter } from "bad-words";
//import useMediaQuery from "@mui/material/useMediaQuery";
import AWS from "aws-sdk";

import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import History from "./History";
import MKTypography from "components/MKTypography";

AWS.config.update({
  region: "us-east-1", // Your region
  accessKeyId: "AKIAUSD2YZRH75J5NCW6",
  secretAccessKey: "pi/fwC0fAyoIW53WdH/B+9el10JNAYNx7XT67XvB",
});
const lambda = new AWS.Lambda();

function CalculateNumStoriesCreatedToday() {
  const today = new Date().toString().substring(0, 15);
  const storyLog = localStorage.getItem("storyLog");
  if (storyLog === null) {
    return 0;
  } else {
    if (!storyLog.includes(today)) {
      return 0;
    } else {
      return storyLog.split(today)[1].split("\t").length;
    }
  }
}

function Create() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const titleRef = useRef(title);
  const inputRef = useRef(null);
  const submittedRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [labelShrink, setLabelShrink] = useState(false);

  //const isMediumScreen = useMediaQuery("max-width:960px");
  //const isLargeScreen = useMediaQuery("(min-width:961px) and (max-width:2000px)");

  const numStoriesCreatedToday = CalculateNumStoriesCreatedToday();
  const [maxStoriesPerDay, setMaxStoriesPerDay] = useState(2);
  const maxStoriesPerDayRef = useRef(maxStoriesPerDay);

  let submitted = false;
  const filter = new Filter();

  useEffect(() => {
    if (localStorage.getItem("lastSharedDate") === new Date().toString().substring(0, 15)) {
      setMaxStoriesPerDay(4);
      maxStoriesPerDayRef.current = 4;
    }
  }, []);

  async function getStoryFromLambda(title) {
    const params = {
      FunctionName: "CreateWordSoupStory", // Your Lambda function name
      InvocationType: "RequestResponse",
      Payload: JSON.stringify({
        body: {
          title: title,
          userId: localStorage.getItem("userId"),
        },
      }),
    };

    try {
      const result = await lambda.invoke(params).promise();
      const payload = JSON.parse(result.Payload).body;
      return payload;
    } catch (err) {
      console.log("Error");
    }
  }

  useEffect(() => {
    titleRef.current = title;
    inputRef.current.querySelector("input").value = title;
  }, [title]);

  const handleInputChange = (event) => {
    setTitle(event.target.value);
  };

  const submitGenRequest = async () => {
    if (
      titleRef.current !== "" &&
      !submitted &&
      titleRef.current.length < 100 &&
      !filter.isProfane(titleRef.current) &&
      numStoriesCreatedToday < maxStoriesPerDayRef.current
    ) {
      submitted = true;
      setLoading(true);
      const storyData = await getStoryFromLambda(titleRef.current);
      if (storyData === undefined) {
        setLoading(false);
        return;
      }
      const story = storyData.story;
      navigateToInputPage(titleRef.current, story);
    } else if (numStoriesCreatedToday === maxStoriesPerDayRef.current) {
      setLabelShrink(true);
      setTitle("");
      const input = inputRef.current.querySelector("input");
      input.placeholder = "Daily limit reached - try again tomorrow";
      input.value = "";
      input.style.outline = "2px solid red";
      input.style.borderRadius = "6px";

      setTimeout(() => {
        input.style.outline = "none";
      }, 1500);
    } else if (titleRef.current !== "" && !submitted) {
      setLabelShrink(true);
      setTitle("");
      const input = inputRef.current.querySelector("input");
      input.InputLabelProps = { shrink: true };
      input.placeholder = "Invalid response";
      input.value = "";
      input.style.outline = "2px solid red";
      input.style.borderRadius = "6px";
      setTimeout(() => {
        input.style.outline = "none";
      }, 1500);
    }
  };

  const navigateToInputPage = (title, story) => {
    const url = `/input`;
    navigate(url, {
      state: { title: title, story: story },
    });
  };

  const handleKeyUp = async (event) => {
    if (
      event.key === "Enter" &&
      titleRef.current !== "" &&
      !submitted &&
      inputRef.current.querySelector("input") === document.activeElement
    ) {
      submitGenRequest();
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="200px"
    >
      <MKTypography variant="body2" mt="30px" align="center" sx={{ whiteSpace: "pre-line" }}>
        {maxStoriesPerDay - numStoriesCreatedToday}/{maxStoriesPerDay} Daily Stories Remaining
        {maxStoriesPerDay === 2 && numStoriesCreatedToday === 2 && "\nShare for 2 free stories"}
      </MKTypography>
      <MKBox display="flex" flexDirection="row" alignItems="center" mt="15px">
        <MKBox mr="10px" mt="7px">
          <ShuffleIcon
            onClick={() => setTitle(randomTitle())}
            fontSize="large"
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "#f3661e",
              },
            }}
          />
        </MKBox>
        <TextField
          autoComplete="off"
          onChange={(event) => handleInputChange(event)}
          InputLabelProps={{
            shrink: labelShrink || title !== "",
          }}
          value={title}
          label={"Title"}
          ref={inputRef}
          sx={{
            width: "40vw",
            mr: 2,
            input: {
              fontSize: "1rem",
            },
            label: {
              fontSize: "1rem",
            },
          }}
        />
        <Button
          variant="contained"
          onClick={() => submitGenRequest()}
          ref={submittedRef}
          sx={{
            mb: "3px",
            height: "30px",
            width: "40px",
            backgroundColor: "#f3661e",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1rem",
            "&:hover": {
              backgroundColor: "#ed7a40",
            },
            "&:focus:not(:hover)": {
              backgroundColor: "#ed7a40",
            },
          }}
        >
          {loading ? (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                "& .MuiCircularProgress-circle": {
                  stroke: "white",
                },
              }}
            />
          ) : (
            "GO"
          )}
        </Button>
      </MKBox>
      <History />
    </MKBox>
  );
}

export default Create;
