import MKBox from "components/MKBox";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import NewStories from "./NewStories";
import TopStories from "./TopStories";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import AWS from "aws-sdk";

AWS.config.update({
  region: "us-east-1", // Your region
  accessKeyId: "AKIAUSD2YZRH75J5NCW6",
  secretAccessKey: "pi/fwC0fAyoIW53WdH/B+9el10JNAYNx7XT67XvB",
});

const lambda = new AWS.Lambda();

async function downloadStoryUsingLambda(storyId) {
  const params = {
    FunctionName: "DownloadWordSoupStoryFromS3", // Your Lambda function name
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({ body: storyId }),
  };

  try {
    const result = await lambda.invoke(params).promise();
    const payload = JSON.parse(result.Payload);
    return payload.body;
  } catch (err) {
    console.error("Error");
  }
}

const formatUTCDate = (date) => {
  return (
    date.substring(0, 3) + date.substring(7, 11) + date.substring(4, 7) + date.substring(11, 16)
  );
};

async function getDynamoDBLog() {
  const key = formatUTCDate(new Date().toUTCString());
  const params = {
    TableName: "snaplibs-hourly-log",
    Key: {
      DateTime: key,
    },
  };

  const docClient = new AWS.DynamoDB.DocumentClient();

  try {
    const data = await docClient.get(params).promise();
    if (data.Item === undefined) {
      const newKey = formatUTCDate(
        new Date(new Date().setDate(new Date().getDate() - 1)).toUTCString()
      );
      const newParams = {
        TableName: "snaplibs-hourly-log",
        Key: {
          DateTime: newKey,
        },
      };
      const newData = await docClient.get(newParams).promise();
      if (newData.Item !== undefined) {
        return newData.Item;
      }
      return { log: "Izi8mMCD" };
    }
    return data.Item;
  } catch (err) {
    console.error("Error", err);
  }
}

function Discover() {
  const [sort, setSort] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  const getRandomStoryId = async () => {
    const data = await getDynamoDBLog();
    // Select random storyId from data
    const storyIds = data.log.split(" ").slice(1);
    const randomIndex = Math.floor(Math.random() * storyIds.length);
    return storyIds[randomIndex];
  };

  const beginRandomStory = async () => {
    const storyId = await getRandomStoryId();
    const storyData = await downloadStoryUsingLambda(storyId);
    navigate(`/mysteryInput`, { state: { storyData } });
    return;
  };

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="200px"
    >
      <MKBox
        width="80vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{ minWidth: "140px", maxWidth: "140px", mt: "35px" }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sort}
              label="Sort By"
              onChange={handleChange}
              IconComponent={null}
              sx={{
                width: "100%",
                height: "30px", // Adjust the height as needed
                "& .MuiInputBase-root": {
                  height: "30px", // Adjust the height as needed
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  height: "100%", // Ensure the content is centered vertically
                },
              }}
            >
              <MenuItem value={0}>New</MenuItem>
              <MenuItem value={1}>Top</MenuItem>
            </Select>
          </FormControl>
          <ArrowDropDownIcon sx={{ ml: "-30px", mt: "5px" }} />
          <Button
            size="large"
            onClick={() => beginRandomStory()}
            variant="contained"
            sx={{
              fontSize: "0.5rem",
              backgroundColor: "#E5A52A",
              color: "#fff",
              minWidth: "30px",
              maxWidth: "30px",
              minHeight: "30px",
              maxHeight: "30px",
              alignItems: "center",
              justifyContent: "center",
              padding: "0",
              margin: "0",
              marginLeft: "20px",
              "&:hover": {
                backgroundColor: "#fcbb3d",
              },
              "&:focus:not(:hover)": {
                backgroundColor: "#fcbb3d",
              },
            }}
          >
            <LightbulbIcon color="#fff" fontSize="small" />
          </Button>
        </Box>
        {sort === 0 ? (
          <NewStories />
        ) : sort === 1 ? (
          <TopStories />
        ) : (
          <CircularProgress
            size={24}
            sx={{
              color: "white",
              backgroundColor: "transparent",
              "& .MuiCircularProgress-circle": {
                stroke: "blue",
              },
            }}
          />
        )}
      </MKBox>
    </MKBox>
  );
}

export default Discover;
